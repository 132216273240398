/* NYXNoCode.css */

.nyx-nocode {
    font-family: Arial, sans-serif;
    background-color: #2e2e2e;
    color: #f0f0f0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.headerImage {
    width: 300px;
    border-radius: 10px;
}

.headerTitle {
    font-family: Cinzel Decorative, cursive;
    font-size: 3rem;
    margin: 20px 0 10px;
}
.inset-image {
    width:300px;
    padding-left: 20px;
}
.content {
    text-align: center;
}

.contentImage {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
}

.footerImage {
    width: 200px;
    border-radius: 10px;
}

.story {
    font-size: 1.2rem;
    line-height: 1.6;
}

.container {
    max-width: 800px;
    margin: 20px auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
}

.videoList {
    list-style-type: none;
    padding: 0;
}

.videoItem {
    padding: 10px;
    background: #e9e9e9;
    margin: 10px 0;
    border-radius: 5px;
}

.catalogLinkContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.catalogLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #007BFF;
    background: #f8f8f8;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.catalogImage {
    width: 50px;
    margin-right: 20px;
}

.catalogTextContainer {
    text-align: left;
}

.catalogTitle {
    margin: 0 0 5px 0;
    font-size: 1.5rem;
    color: #333;
}

.catalogDescription {
    margin: 0;
    font-size: 1rem;
    color: #555;
}
