/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 15px;
  background-color: #000000;

}

/*nyx nocode
 */
body {
  font-family: Arial, sans-serif;
  color: white;
  background-color: #000000;
  background-image: url('./img/purple_mountains-generated.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
a.contact-link {
  color: white;
}
.navbar a {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  animation: gradient 3s infinite;
  background: linear-gradient(45deg, #ed10cd, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes gradient {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
section {
  padding: 50px;
  border-radius: 10px;
  margin: 50px;
}
.whitepaper  {
  background-color: white;
  color: black;
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
  text-align: left;
  max-width:1000px;
}
.whitepaper li {
  text-align:left;
}
.whitepaper h1, h2 {
  text-align: center;
}
.whitepaper hr {
  border: 0;
  height: 2px;
  background-color: blueviolet;
  margin: 20px 0;
  text-align:left;
}
.whitepaper img {
  display: block;
  margin: 0 auto;
  width: 150px;
  padding-bottom:20px;
}
/*nyx nocode end*/

caption {
  display:block;
  font-size:1em;
}
p  {
  margin-left:20px;
  margin-right:20px;
  text-align:left;
}

.breadcrumbs li {
  list-style: none;
  display:inline-flex;
  text-justify: left;
}
.blog-summary {
  padding:20px 0 0 20px;
  width:100%;
  margin-bottom:20px;
  border-top: white;
  font-size:.8em;
  display:inline-flex;

}
.blog-summary h2 {
  padding-top: 2px;
  display:inline-flex;
  text-align: left;
}
.bloglist {
  display:table;
  text-align: left;

}
.bloglist img {
  padding-bottom:20px;
}
.bloglist h2 {
  text-align:left;
}
.blog-image {
  margin-left: 20px;

}
.blog-image img {
  display: inline-flex;
}
.home-text {

}
.content {
}
.content p {
  display:block;
  text-align: left;
  font-size:1.25em;

}


.claimedat {
  display:inline-block;
}
p.subscribe {
text-align: center;
}
.manifest {
  display:block;
}
.example img {
  max-width:400px;
}
.content h1, h2 {
  text-align:center;
}
.content h1 {
  font-size: 2.5em;
}
.content ul, div {
/*  text-align:left;*/
}

.App {
  text-align: center;

}
.hero img {
margin-top:60px;
display:inline-block;
  width:100%;
}
.fox {
  max-width:300px;
}
.herosmall {
  width: 200px;
  float:left;
  margin-right:20px;
}
.blog-summary h2 {
  text-align:left;
}
.moonrise {
  width:100%;
  align-content: center;
  justify-content: center;
  margin-top:40px;
  margin-bottom:40px;
}
.logo {
  /* float:right */
  width:80%;
}

 .nav-item {
  font-size: 12px;
  list-style: none;
  padding:0;
}
.footer-links {
  display:block;
}
.filebabyfooter {
  width:200px;
  float:right;
  padding-bottom:20px;
}
.insetImageLeft {
  display: block; /* Display block to make sure it's not inline */
  width: 250px; /* Set the width of the image */
  float: left; /* Float to the left */
  margin-right: 20px; /* Add some space between the image and the text */
}

/* Float the image to the right */
.insetImageRight {
  display: block; /* Display block to make sure it's not inline */
  width: 250px; /* Set the width of the image */
  float: right; /* Float to the right */
  margin-left: 20px; /* Add some space between the image and the text */
}
.imageContainer {
  text-align: center; /* Centers the content (the image) inside the container */
  padding:0;
}
/* Style for the dropdown button */
.content {
  margin-top:200px;
}
.content.whitepaper {
margin-top:0px;
  display:inline-block;

}

.dropbtn {
  color: black;
  padding: 0 0 0 0;
  font-size: 12px;
  border: none;
  cursor: pointer;
  text-decoration: none; /* Remove underline from link */
  display: inline-block; /* Allow pseudo-elements */
  position: relative; /* Establish positioning context */
  background-color: #666666;

}

/* Add a small arrow after the dropdown link */
.dropbtn::after {
  display:none;
  content: ' ▼';
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #f7d347;
  padding-right: 5px;
  position: absolute; /* Position the arrow relative to the dropbtn */
  right: -20px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #666666;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f9f9f9;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
}
span.readytoinspect {
display:none;
}
/* Style for the image */
.centeredImage {
  display: block; /* Treats the image as a block-level element */
  margin: 0 auto; /* Sets the top and bottom margins to 0 and left and right margins to auto, centering the image */
  width: 800px; /* Optional: sets the width of the image */
}
.centeredImageFull {
  display: block; /* Treats the image as a block-level element */
  margin: 0 auto; /* Sets the top and bottom margins to 0 and left and right margins to auto, centering the image */
  width: 500px; /* Optional: sets the width of the image */
}
.hero {
  background-color: #050220;
}

.top-heading {
  float:left;
}
/*this is used in inspect gif*/
.services {
  padding: 25px 0;
  text-align: center;
  background-color: white;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);

}

.services h2 {
  color: #2b2d30;
}

.footer {
  background-color: #000000;
  opacity: 95%;
  color: #ffffff;
  padding: 5px;
}

.privacy-policy {
  padding: 50px 20px;
  background-color: #61dafb;
  text-align: left;
  font-size: 0.5em;
}

.privacy-policy h2, .terms-of-use h2 {
  color: #2b2d30; /* Or the primary color of your theme */
}
.terms-of-use {
  padding: 0 20px;
  background-color: #61dafb;
  text-align: left;
  font-size: 0.5em;
}
/* Add to App.css */

.subscribe-btn {
  background-color: #f7d347; /* YELLOW background */
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 50px 0 50px 0;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  width: 250px;
}

button.signin {
  background-color: blue; /* Green background */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

button.signin:hover {
  background-color: #585660; /* Darker shade of green on hover */
}
.subscribe-btn:hover {
  opacity:95%; /* Darker shade of green on hover */

}
.content a, a:visited {
  text-decoration:underline;
  color: black;
}
.caifb {
}
img.caifb {
  width:550px;
  margin-top:50px;

}
.video iframe {
  width: 100%;
  max-width: 560px; /* Maximum width of the video */
  height: auto; /* Adjust height automatically */
  aspect-ratio: 16 / 9; /* Maintain aspect ratio of 16:9 */
  margin-top: 10px;
  margin-bottom:60px;
}
.video h2 {
  margin-bottom:0;
  padding-bottom:0;
}
/* Styles for when menu is active */
.active {
  transform: translateX(0);
}
.homepage-text {
  display: flex;
  margin-bottom: 20px;
}
.even {
  font-size:1.5em;
  padding:100px;
  background-color: #d6d6d3;
  font-weight:bold;
  margin-bottom:20px;
}
.odd {
  font-size:1.5em;
  padding:200px;
  background-color: #495a71;
  background-blend-mode: saturation;
  color:white;
  font-weight:bold;
}
@media (min-width: 769px) {
  /* Hide the hamburger menu icon */
  .menu-icon {
    display: none;
  }

  .filebabyfooter {
    display:none;
  }

  /* Style the navigation list for larger screens */
  .nav-list {
    display: flex;
    justify-content: center; /* Center the nav items horizontally */
    align-items: center; /* Align the nav items vertically */
    list-style: none; /* Remove list bullets */
    padding-left: 0; /* Remove padding */
    background-color: #000000; /* Your desired background color */
    width: 100%; /* Full width of the menu */
    margin-top:0;
    opacity:70%;
  }

  /* Style each navigation item */
  .nav-item {
    padding: 18px 10px; /* Add padding to each nav item for spacing */
    text-align:left;
    opacity:100%;
  }

  /* Style each navigation link */
  .nav-item a {
    text-decoration: none; /* Remove underline from links */
    color: #ffffff; /* Set text color for the links */
    font-size: 24px;
    font-weight: bolder;
    opacity:100%;
  }

  /* Additional styles for the 'X' close icon, if needed */
  .menu-icon span {
    font-size: 2rem; /* Set a large font size for the 'X' icon */
    cursor: pointer; /* Change mouse to pointer when hovering over the 'X' */
  }

  .clog img {
    width:50px;}

}

@media (max-width: 768px) {

  .menu {
    display: none; /* Hide by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Black overlay */
    /*background-color: #bdaba1;*/
    z-index: 99;
    justify-content: center;
    align-items: center;
  }

  .menu.active {
    display: flex; /* Show the full-screen menu */
    flex-direction: column;
  }

  .nav-list {
    display: none; /* Hide by default */
    list-style-type: none;
    width: 100%; /* Full width */
    height: 100%; /* Full height, if you want the menu to cover the entire screen */
    padding-top: 1.5rem; /* Adjust if needed */
    box-sizing: border-box;
    flex-flow: column nowrap;
  }

  .nav-list.active {
    display: flex; /* Show on active */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .nav-item {
    color: white; /* Light text for visibility */
    padding: 1rem; /* Spacing around items */
    text-align: left;
    width: 100%; /* Full width */
    font-size: 1em;
  }
  .nav-item a {
    text-decoration: none;
    color: white; /* Light text for visibility */
    display: inline-block; /* Make the link fill the list item for easier touch */
  }

  .menu-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    z-index: 100; /* Above the overlay */
    margin-right: 5px;
  }

  .menu-icon div, .menu-icon span {
    width: 2rem; /* Size of the icon */
    height: 0.15rem; /* Height of the icon lines */
    background-color: white; /* Color of the icon lines */
    border-radius: 5px;
    transition: all 0.3s linear;
  }
  .menu-icon div {
    margin-bottom: 0.5rem;
  }

  .menu-icon span {
    height: auto; /* Auto height for the 'X' character */
    font-size: 2rem; /* Size of the 'X' */
    line-height: 2rem; /* Align 'X' with the icon lines */
    text-align:center;
  }


  /* Add a small arrow after the dropdown link */
  .dropbtn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-family: inherit; /* Match the font of other links */
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:white;
  }

  /* Style for the icons */
  .fa-caret-up, .fa-caret-down {
    margin-left: 0.5rem;
  }
  /* Add a small arrow after the dropdown link */
  .dropbtn::after {
    display: none;
    content: ' ▼';
    font-size: 0.8rem;
    margin-left: 0.5rem;
    color: #f7d347;
    padding-right: 5px;
    position: relative; /* Position the arrow relative to the dropbtn */
    right: -250px; /* Adjust as needed */
    top: 50%;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    margin: 0 0 0 0;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    float:right;
    margin-left:2rem;
    margin-right:2rem;
    position: relative;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #f1f1f1;

  }
  .dropdown-content {
    display: none;
    background-color: #bdaba1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropbtn {
  display:block;
    font-size:1em;
    font-weight:normal;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
  }
  .filebabylarge {
    text-align:center;
  }
  .filebabylarge img {
    width:300px;
  }
  .home-text {
    font-size: 1em;
    margin-left:20px;
    margin-right:20px;

  }
  .homepage-text {
    display: flex;
    margin-bottom: 20px;
  }
  .content p {
    display:block;
    text-align: left;
  }
 .subscribe {
    text-align: center;
  }
  .content {
    margin-top:0px;
  }
  img.caifb {
    margin-top:50px;
    width:100%;
  }
  .even {
    font-size:1em;
    padding:50px;
    margin-bottom:10px;
  }
.caifb {
    padding:0px;
  margin:0px;
   display:block;
  }
  .odd {
    font-size:1em;
    padding:50px;
    margin-bottom:10px;
  }
  .clog img {
    width:50px;
  }
  @media (max-width: 399px) {
    .filebabylarge img {
      width:200px;
      padding-bottom: 25px;
    }
    .clog img {
      width: 50px;
    }
    .caifb {
      display:none;
    }
    .video iframe {
      width: 300px;
      height: auto; /* Adjust height automatically */
      aspect-ratio: 16 / 9; /* Maintain aspect ratio of 16:9 */
      margin-top: 60px;
    }
  }
}
