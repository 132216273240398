.nyx-catalog {
    background-color: #2e2e2e;
}
.nyx-catalog h1 {
    text-align: center;
    color: #ff6584;
}

.hero-img {
    width:100%;
}

.hero-img img {
    transition: opacity 2s ease-in-out;
}
.catalog {
    display: flex;
    flex-direction: column;
    align-items: center;
    color:white;
}
.whitefont {
    color:white;
}
.example {
    background-color: #1f1f1f;
    margin: 10px;
    padding: 15px;
    border-radius: 8px;
    width: 80%;
}

.example a {
    color: #ff6584;
    text-decoration: none;
}

.example a:hover {
    text-decoration: underline;
}
.header-image {
    width: 300px;
    border-radius: 10px;
    margin-top: 200px;
}

@media (max-width: 600px) {
    .example {
        width: 100%;
    }
}
